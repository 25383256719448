import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "./home.scss";

import { EffectCoverflow, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import { Box, Container, Typography } from "@mui/material";

import dayjs from "dayjs";
import { useState } from "react";
import Loading from "../../components/loader/Loading";
import Patrocinadores from "../../components/patrocinadores/Patrocinadores";
import { GENRES } from "../../constants/constants";
import { useAppSelector } from "../../context/hooks/hooks";
import { COLABORADORES, PATROCINADORES } from "../../utils/constants";
import useQueryHomeWomans from "../../utils/hooks/rankings/useQueryHomeWomen";
import useQueryRankings from "../../utils/hooks/rankings/useQueryRankings";
import useQueryEvents from "../../utils/hooks/useQueryEvents";
import useSetPageName from "../../utils/hooks/useSetPageName";
import { useWindowSize } from "../../utils/hooks/useWindowSize";
import { TRankingFilters } from "../../utils/schemas/rankings";
import HomeEventCard from "./homeEventCard/HomeEventCard";
import RankingCard from "./rankingCard/RankingCard";

export default function Home() {
  useSetPageName([{ name: "Inicio", path: "/" }]);
  const { width } = useWindowSize();
  const isMobile = width && width < 768;

  const { data, isLoading } = useQueryEvents();
  const { categories } = useAppSelector((state) => state.formsOptions);

  const [selectedCategoryMen, setSelectedCategoryMen] = useState<string | null>("");
  const [selectedCategoryWomen, setSelectedCategoryWomen] = useState<string | null>("");

  const [menFilters, setMenFilters] = useState<TRankingFilters>({
    date: dayjs().toISOString(),
    weight: "",
    search: "",
    sportCode: "",
    genre: GENRES.MEN,
    page: 1,
    perPage: 10,
    category: "",
  });

  const [womenFilters, setWomenFilters] = useState<TRankingFilters>({
    date: dayjs().toISOString(),
    weight: "",
    search: "",
    sportCode: "",
    genre: GENRES.WOMEN,
    page: 1,
    perPage: 10,
    category: "",
  });

  const handleFilterChange = (
    genre: string,
    filter: keyof TRankingFilters,
    value: string | undefined | null | number
  ) => {
    if (genre === GENRES.MEN) {
      setMenFilters((prev) => ({
        ...prev,
        [filter]: value,
      }));
    } else if (genre === GENRES.WOMEN) {
      setWomenFilters((prev) => ({
        ...prev,
        [filter]: value,
      }));
    }
  };

  const handleChangeCategory = (id: string, genre: string) => {
    if (genre === GENRES.MEN) {
      setSelectedCategoryMen(id);
    } else if (genre === GENRES.WOMEN) {
      setSelectedCategoryWomen(id);
    }
    const category = categories?.find((category) => category.id === id);
    handleFilterChange(genre, "weight", category?.weight);
  };

  const {
    data: menData,
    isLoading: loadingMens,
    isRefetching: refetchingMens,
  } = useQueryRankings(menFilters);
  const {
    data: womenData,
    isLoading: loadingWomans,
    isRefetching: refetchingWomans,
  } = useQueryHomeWomans(womenFilters);

  return (
    <Container className="home-container">
      <Typography component="h4" variant="h5" className="home-title">
        Eventos
      </Typography>
      {/* EVENTOS CAROUSEL */}
      <Box className="carouselBox">
        {isLoading ? (
          <Loading />
        ) : (
          data && (
            <Swiper
              effect={"coverflow"}
              grabCursor={true}
              centeredSlides={true}
              slidesPerView={3}
              coverflowEffect={{
                rotate: 50,
                stretch: 0,
                depth: 100,
                modifier: 1,
                slideShadows: true,
              }}
              navigation={isMobile ? false : true}
              modules={[EffectCoverflow, Navigation]}
              className="mySwiper"
            >
              {data.map((event) => {
                return (
                  <SwiperSlide key={event.id}>
                    <HomeEventCard event={event} />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          )
        )}
      </Box>
      <Box className="home-rankings">
        <Box className="home-ranking-container">
          <Typography variant="h4" component="h4" className="home-title">
            Ranking masculino
          </Typography>
          <RankingCard
            name="Ranking Masculino"
            list={menData?.data}
            rankingFilters={menFilters}
            handleFilterChange={handleFilterChange}
            handleChangeCategory={handleChangeCategory}
            selectedCategory={selectedCategoryMen}
            isLoading={loadingMens}
            isRefetching={refetchingMens}
            genre={GENRES.MEN}
          />
        </Box>
        <Box className="home-ranking-container">
          <Typography variant="h4" component="h4" className="home-title">
            Ranking femenino
          </Typography>
          <RankingCard
            name="Ranking Femenino"
            list={womenData?.data}
            rankingFilters={womenFilters}
            handleFilterChange={handleFilterChange}
            handleChangeCategory={handleChangeCategory}
            selectedCategory={selectedCategoryWomen}
            isLoading={loadingWomans}
            isRefetching={refetchingWomans}
            genre={GENRES.WOMEN}
          />
        </Box>
      </Box>

      {/* COLABORADORES */}
      <Typography variant="h4" component="h4" className="home-title">
        Nuestros Colaboradores
      </Typography>
      <Patrocinadores data={COLABORADORES}></Patrocinadores>
      {/* PATROCINADORES */}
      <Typography variant="h4" component="h4" className="home-title">
        Nuestros Patrocinadores
      </Typography>
      <Patrocinadores data={PATROCINADORES}></Patrocinadores>
    </Container>
  );
}
