import { Box, ListSubheader, MenuItem, Popover, Select } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { CATEGORY_NAMES, GENRES } from "../../constants/constants";
import { useAppSelector } from "../../context/hooks/hooks";
import { TRankingFilters } from "../../utils/schemas/rankings";
import MenuPopover from "../menuPopover/MenuPopover";
import MenuPopoverDateItem from "../menuPopover/menuPopoverDateItem/MenuPopoverDateItem";
import MenuPopoverItem from "../menuPopover/menuPopoverItem/MenuPopoverItem";
import MenuPopoverMultiple from "../menuPopover/menuPopoverMultipleListItem/MenuPopoverMultipleListItem";

type Props = {
  selectedCategory: string | null;
  handleChangeCategory: (id: string) => void;
  rankingFilters: TRankingFilters;
  handleFilterChange: (
    filter: keyof TRankingFilters,
    value: string | undefined | null | number
  ) => void;
  anchorEl: HTMLElement | null;
  handleClose: () => void;
};

const RankingFilters = ({
  selectedCategory,
  handleChangeCategory,
  rankingFilters,
  handleFilterChange,
  anchorEl,
  handleClose,
}: Props) => {
  const { categories, sports } = useAppSelector((state) => state.formsOptions);

  const getNumericWeight = (weight: string) => {
    const numericPart = weight.match(/[\d.]+/);
    return numericPart ? parseFloat(numericPart[0]) : 0;
  };

  const sportsOptions = sports?.map((sport) => ({
    value: sport.code,
    label: sport.name,
  }));

  //Filter categories by genre
  const menCategories = categories
    ?.filter(
      (category) =>
        category.genre === GENRES.MEN &&
        category.sportCode === rankingFilters.sportCode &&
        category.categoryName === rankingFilters.category
    )
    .sort((a, b) => getNumericWeight(a.weight) - getNumericWeight(b.weight));

  const womenCategories = categories
    ?.filter(
      (category) =>
        category.genre === GENRES.WOMEN &&
        category.sportCode === rankingFilters.sportCode &&
        category.categoryName === rankingFilters.category
    )
    .sort((a, b) => getNumericWeight(a.weight) - getNumericWeight(b.weight));

  const menOptions = (menCategories || [])?.map((category) => category.weight);
  const womenOptions = (womenCategories || [])?.map((category) => category.weight);

  const categoryOptions = CATEGORY_NAMES?.map((category) => ({
    value: category,
    label: category,
  }));

  return (
    <Box className="ranking-table-top-filters">
      <Select
        className="input-select ranking-select-field"
        defaultValue={""}
        value={rankingFilters["sportCode"]}
        onChange={(e) => handleFilterChange("sportCode", e.target.value)}
        fullWidth
        displayEmpty
      >
        <MenuItem value="">Deporte: Todos</MenuItem>
        {sports?.map((sport) => (
          <MenuItem value={sport.code} key={sport.id}>
            {sport.name}
          </MenuItem>
        ))}
      </Select>
      <Select
        className="input-select ranking-select-field"
        defaultValue={""}
        value={rankingFilters["category"]}
        onChange={(e) => handleFilterChange("category", e.target.value)}
        fullWidth
        displayEmpty
      >
        <MenuItem value="">Categoría: Todos</MenuItem>
        {CATEGORY_NAMES?.map((sport) => (
          <MenuItem value={sport} key={sport}>
            {sport}
          </MenuItem>
        ))}
      </Select>
      <Select
        className="input-select ranking-select-field"
        defaultValue=""
        value={selectedCategory}
        fullWidth
        displayEmpty
        onChange={(e) => handleChangeCategory(e.target.value ?? "")}
      >
        <MenuItem value="">Peso: Todos</MenuItem>
        <ListSubheader>Hombre</ListSubheader>
        {menCategories?.map((category) => (
          <MenuItem value={category.id} key={category.id}>
            {category.weight}
          </MenuItem>
        ))}
        <ListSubheader>Mujer</ListSubheader>
        {womenCategories?.map((category) => (
          <MenuItem value={category.id} key={category.id}>
            {category.weight}
          </MenuItem>
        ))}
      </Select>
      <DesktopDatePicker
        value={rankingFilters["date"] ? dayjs(rankingFilters["date"]) : null}
        format="DD/MM/YYYY"
        className="input-select ranking-select-field ranking-date-picker"
        onChange={(value) => handleFilterChange("date", value?.toISOString())}
        slotProps={{
          field: {
            clearable: true,
            onClear: () => handleFilterChange("date", ""),
          },
        }}
        disableFuture
      />
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={!!anchorEl}
        onClose={handleClose}
      >
        <MenuPopover title=" FILTRAR POR:">
          <MenuPopoverItem
            title="deporte"
            options={sportsOptions}
            selectedValue={rankingFilters["sportCode"]}
            onSelectionChange={(value) => handleFilterChange("sportCode", value)}
          />
          <MenuPopoverItem
            title="categoria"
            options={categoryOptions}
            selectedValue={rankingFilters["category"]}
            onSelectionChange={(value) => handleFilterChange("category", value)}
          />
          <MenuPopoverMultiple
            title="Peso"
            subtitles={["Masculino", "Femenino"]}
            options={[menOptions, womenOptions]}
            onSelectionChange={(value) => handleChangeCategory(value)}
            selectedValue={selectedCategory || ""}
          />
          <MenuPopoverDateItem
            selectedValue={rankingFilters["date"]}
            onSelectionChange={(value) => handleFilterChange("date", value)}
            disableFuture
          />
        </MenuPopover>
      </Popover>
    </Box>
  );
};

export default RankingFilters;
