import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from "@mui/material";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import { TEventId } from "../../../utils/schemas/event";

import { formatHours, mapDateToRightFormat } from "../../../utils/date";
import "./event-info.scss";

type Props = {
  data: TEventId;
};

const EventInfo = ({ data }: Props) => {
  console.log("la data del evento", data);

  const programSchedules = data?.programSchedules ? JSON.parse(data.programSchedules) : [];

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} md={6}>
        {data?.programSchedules && <Accordion className="event-info-accordion">
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
            className="event-info-accordion__summary"
          >
            <Typography>Cronograma</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {programSchedules.map((schedule: any, index: number) => (
              <Typography key={index}>
                {mapDateToRightFormat(schedule.date)} - {formatHours(schedule.time)} -{" "}
                {schedule.description}
              </Typography>
            ))}
          </AccordionDetails>
        </Accordion>}
        {data?.organizatorName && <Accordion className="event-info-accordion">
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            className="event-info-accordion__summary"
          >
            <Typography>Organizador</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>Nombre: {data?.organizatorName}</Typography>
            <Typography>Tipo: {data?.organizatorType}</Typography>
          </AccordionDetails>
        </Accordion>}
        {data?.responsables && <Accordion className="event-info-accordion">
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
            className="event-info-accordion__summary"
          >
            <Typography>Contacto con responsables</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <>
              {data?.responsables?.map((responsable: any, index: number) => (
                <div key={index}>
                  <Typography>
                    Nombre: {responsable.name}, {responsable.surname}
                  </Typography>
                  <Typography>Teléfono: {responsable.phone}</Typography>
                  <Typography>Responsabilidad: {responsable.responsability}</Typography>
                </div>
              ))}
            </>
          </AccordionDetails>
        </Accordion>}
        {data?.refereeDescription && <Accordion className="event-info-accordion">
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
            className="event-info-accordion__summary"
          >
            <Typography>Arbitraje</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{data.refereeDescription}</Typography>
          </AccordionDetails>
        </Accordion>}
        {data.gifts && <Accordion className="event-info-accordion">
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
            className="event-info-accordion__summary"
          >
            <Typography>Premios</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{data.gifts}</Typography>
          </AccordionDetails>
        </Accordion>}
        {data?.hotelName && <Accordion className="event-info-accordion">
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
            className="event-info-accordion__summary"
          >
            <Typography>Alojamientos</Typography>
          </AccordionSummary>
          {data.hotelName && (
            <AccordionDetails>
              <Typography>Nombre hotel: {data.hotelName}</Typography>
              <Typography>Dirección: {data.hotelAddress}</Typography>
              <Typography>Descripción: {data.hotelDescription}</Typography>
              <Typography>
                Url Google Maps:{" "}
                <a href={data.hotelMapsUrl} target="_blank" rel="noreferrer noopener">
                  {data.hotelMapsUrl}
                </a>
              </Typography>
            </AccordionDetails>
          )}
        </Accordion>}
      </Grid>
    </Grid>
  );
};

export default EventInfo;
