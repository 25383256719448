export const PATROCINADORES = [
  {
    image: "https://www.rfejudo.com/wp-content/uploads/DAEDO-1.png",
    url: "https://www.daedo.com/es/",
  },
  { image: "https://www.rfejudo.com/wp-content/uploads/CSD-1.png", url: "https://www.csd.gob.es/" },
  {
    image: "https://www.rfejudo.com/wp-content/uploads/ADO.png",
    url: "https://www.ado.es/",
  },
  {
    image: "https://www.rfejudo.com/wp-content/uploads/iberdrola_web.png",
    url: "https://www.iberdrola.com/",
  },
];

export const COLABORADORES = [
  { image: "https://www.rfejudo.com/wp-content/uploads/IJF-1.png", url: "https://www.ijf.org/" },
  { image: "https://www.rfejudo.com/wp-content/uploads/EJU-1.png", url: "https://www.eju.net/" },
  { image: "https://www.rfejudo.com/wp-content/uploads/COE.png", url: "https://www.coe.es/" },
  {
    image: "https://www.rfejudo.com/wp-content/uploads/Ayuntamiento_Malaga.png",
    url: "https://www.malaga.eu/",
  },
  {
    image: "https://www.rfejudo.com/wp-content/uploads/Ayuntamiento_Fuengirola.png",
    url: "https://www.fuengirola.es/",
  },
  {
    image: "https://www.rfejudo.com/wp-content/uploads/maxrendimiento.png",
    url: "https://maxrendimiento.com/",
  },
  {
    image: "https://www.rfejudo.com/wp-content/uploads/Universidad_Politecnica.png",
    url: "https://www.inef.upm.es/",
  },
  { image: "https://www.rfejudo.com/wp-content/uploads/INEF.png", url: "https://www.inef.upm.es/" },
];

export const ERROR_MESSAGES = {
  MAX_250: "Máximo permitido 250 caracteres",
};

export const IMAGE_DEFAULT = '/images/logos/Logo.png'